.layout {
  display: flex;
  overflow: hidden;
  max-height: 100vh;

  .leftSideContainer {
    width: 100%;
    max-width: 220px;
    background-color: #fff;
    height: 100vh;
    overflow: hidden;
    border-right: 1px solid #bfbfbf;

    .logoContainer {
      height: 70px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      border-bottom: 1px solid #bfbfbf;

      .logo {
        width: 130px;
      }
    }

    .navContainer {
      padding: 20px 10px;
      height: calc(100% - 70px);
      display: grid;
      grid-auto-rows: min-content;
      gap: 10px;
      align-items: center;
      overflow: auto;

      .navLink {
        display: flex;
        align-items: center;
        text-decoration: none;
        gap: 20px;
        font-size: 14px;
        color: #525252;
        padding: 0 10px;
        height: 35px;

        .icon {
          width: 15px;
        }

        &:hover {
          background-color: #edf0fd80;
          color: #000000;
          font-weight: 600;
          border-radius: 8px;
        }
      }
      .activeLink {
        background-color: #edf0fd80;
        color: #000000;
        font-weight: 600;
        border-radius: 8px;
      }
    }
  }

  .rightSideContainer {
    width: 100%;
    max-width: calc(100% - 220px);

    .topBarContainer {
      height: 70px;
      padding: 0 20px;
      border-bottom: 1px solid #bfbfbf;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: auto;
      gap: 20px;

      .leftSide {
        display: flex;
        align-items: center;
        gap: 20px;

        .pageName {
          font-size: 17px;
          font-weight: 700;
        }
      }

      .rightSide {
        display: flex;
        position: relative;
        gap: 20px;

        .logoutCircle {
          display: flex;
          align-items: center;
          gap: 8px;
          color: #525252;
          .firstLetter {
            background-color: white;
            border: 1px solid #4519c5;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            font-weight: 500;
            cursor: pointer;
            // color: white;
            color: #4519c5;
            border-radius: 50%;
            font-size: 25px;
            padding: 2px 12px;
            margin: 0px;
          }
        }

        .logoutDiv {
          border: 1px solid #bfbfbf;
          border-radius: 4px;
          font-size: 15px;
          cursor: pointer;
          display: flex;
          gap: 6px;
          align-items: center;

          .userEmail {
            padding: 9px 10px;
            border-right: 1px solid #bfbfbf;
            min-width: 200px;
          }

          .icon {
            width: 10px;
            height: 7px;
            padding-right: 12px;
            padding-left: 6px;
            margin-top: 6px;
          }
        }
        .generate {
          font-size: 15px !important;
          padding: 9px 19px;
        }
      }
    }
    .logoutButton {
      border: 1px solid #878585 !important;
      padding: 2px;
      position: absolute;
      top: 74px;
      display: flex;
      width: 120px;
      z-index: 99;
      right: 19px;
      background: white;
      color: #131313;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      align-items: center;
      justify-content: center;
      gap: 6px;
      border-radius: 5px;

      .logoutIcon {
        width: 20px;
        height: 20px;
      }
      .logoutBtn {
        // border: 1px solid #878585 !important;
        // position: absolute;
        // top: 60px;
        // width: 254px;
        // z-index: 99;
        // right: 19px;
        // background: white;
        // color: #131313;
        // background-color: white;

        // padding: 10px 0px;
        // border: 1px solid #878585 !important;
        // position: absolute;
        // top: 74px;
        // width: 120px;
        // z-index: 99;
        // right: 19px;
        // background: white;
        // color: #131313;
        // background-color: white;
        // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        border: none !important;
        color: black;
        font-size: 15px !important;
        font-weight: 600;

        // &:hover {
        //   background-color: rgb(235, 232, 232);
        // }
      }
    }
  }
}
