/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::-webkit-scrollbar {
  width: 10px;
  height: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #a1a1a1; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf; 
}
